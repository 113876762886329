import React from 'react';
import Context from './context'
// import MyCal from './MyCal'
import MyCal from './datetimeCal'
import MyButton from './myButton'
import { Combobox } from 'evergreen-ui'
import Link from '@material-ui/core/Link';


class ExeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    componentDidMount() {

    }

    render() {
        return (
            <Context.Consumer>
                {
                    // ctx.value == state
                    ctx => {
                        return (

                            <div className="colf">
                                <MyCal val={ctx.value.fromDateVal} onChangeMethod={ctx.actions.fromDateOnChange} />
                                <MyCal val={ctx.value.toDateVal} onChangeMethod={ctx.actions.toDateOnChange} />

                                <div className="combo_container">


                                    <div style={{ margin: "5px 5px 10px 10px" }}>
                                        <Combobox
                                            className="Aligner-item"

                                            items={ctx.value.reports}
                                            initialSelectedItem={ctx.value.reports[0]}//{ctx.value.targetTypes[1]}
                                            onChange={selected => ctx.actions.onAccountChange(selected)}
                                            placeholder="Accounts"
                                            itemToString={item => item ? item.title : ''}
                                            autocompleteProps={{
                                                title: 'بحث'
                                            }}
                                        />
                                    </div>


                                    <div style={{ margin: "5px 5px 10px 10px" }}>
                                        <Combobox
                                            className="Aligner-item"

                                            items={ctx.value.targetTypes}
                                            initialSelectedItem={ctx.value.targetTypes[0]}//{ctx.value.targetTypes[1]}
                                            onChange={selected => ctx.actions.onTargetTypeChange(selected)}
                                            placeholder="Target Types"
                                            itemToString={item => item ? item.title : ''}
                                            autocompleteProps={{
                                                title: 'بحث'
                                            }}
                                        />
                                    </div>

                                    <div style={{ margin: "5px 5px 10px 10px" }}>
                                        <Combobox
                                            className="Aligner-item"

                                            items={ctx.value.destinations}
                                            initialSelectedItem={ctx.value.destinations[0]}//{ctx.value.targetTypes[1]}
                                            onChange={selected => ctx.actions.onDestinationChange(selected)}
                                            placeholder="Destination"
                                            itemToString={item => item ? item.title : ''}
                                            autocompleteProps={{
                                                title: 'بحث'
                                            }}
                                        />
                                    </div>

                                    {/* {ctx.value.selectedTargetType.id === 0 ?
                                    <div style={{ margin: "5px 5px 10px 10px" }}>
                                        <Combobox
                                            className="Aligner-item"

                                            items={ctx.value.units ? ctx.value.units : []}
                                            initialSelectedItem={ctx.value.units ? ctx.value.units[0] : {}}
                                            onChange={selected => ctx.actions.onUnitChange(selected)}
                                            placeholder="اختر مركبة"
                                            itemToString={item => item ? item.$$user_name : ''}
                                            autocompleteProps={{
                                                title: 'بحث'
                                            }}
                                        />
                                    </div>
                                    :
                                    null

                                }

                                {
                                    ctx.value.selectedTargetType.id === 1 ? */}
                                    <div style={{ margin: "5px 5px 10px 10px" }}>
                                        <Combobox
                                            className="Aligner-item"
                                            items={ctx.value.unitGroups ? ctx.value.unitGroups : []}
                                            initialSelectedItem={ctx.value.unitGroups ? ctx.value.unitGroups[0] : null}
                                            onChange={selected => ctx.actions.onUnitGroupChange(selected)}
                                            placeholder="اختر مجموعة"
                                            itemToString={item => item ? item.$$user_name : ''}
                                            autocompleteProps={{
                                                title: 'بحث'
                                            }}
                                        />
                                    </div>
                                    {/* :
                                        null
                                } */}
                                    {
                                        ctx.value.zones.length > 0 ?
                                            <div style={{ margin: "5px 5px 10px 10px" }}>
                                                <Combobox
                                                    className="Aligner-item"
                                                    key={item => item ? item.id : -1}
                                                    items={ctx.value.filteredZones ? ctx.value.filteredZones : []}
                                                    initialSelectedItem={ctx.value.filteredZones ? ctx.value.filteredZones[0] : null}
                                                    onChange={selected => ctx.actions.onZoneChange(selected)}
                                                    placeholder="اختر المنطقة"
                                                    itemToString={item => item ? item.n : ''}
                                                    autocompleteProps={{
                                                        title: 'بحث'
                                                    }}
                                                />
                                            </div>
                                            :
                                            null
                                    }


                                </div>
                                <MyButton whenClick={ctx.actions.execute} label="تنفيذ"></MyButton>
                                {
                                    // <MyButton whenClick={ctx.actions.export} label="تصدير"></MyButton>

                                    ctx.value.isReady ?
                                        <MyButton whenClick={ctx.actions.export} label="تصدير"></MyButton>
                                        :
                                        null
                                }
                                {/* <div style={{ backgroundColor: "#2F5596" }}>

                                    <img src={"../../assets/img/wenklogo.png"} style={{
                                        display: "block",
                                        margin: "auto",
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                    }} />
                                </div> */}
                            </div>

                        )
                    }
                }
            </Context.Consumer >
        )
    }
}

export default ExeForm;