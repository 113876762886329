import React from "react";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import "./App.css";
// import XLSX from 'xlsx';
import Context from "./components/context";
import MyNav from "./components/MyNav";
import wenkLogo from "./assets/img/wenklogo.png"
import { saveAs } from "file-saver";
import { tsConstructorType } from "@babel/types";
import moment from 'moment';
import { tr } from "date-fns/locale";
const ExcelJS = require("exceljs");

const fileJSON = require("./assets/files/constData.json");

/* global wialon */
/* global xlsx */

const RES_NAME = fileJSON.resourceName;
const TARGET_TYPES = fileJSON.targetTypes;
const DESTINATIONS = fileJSON.destinations;
const REPORTS = fileJSON.repoerMaping
const host = "https://hst-api.wialon.com"
class App extends React.Component {
  constructor(props) {
    // console.log(fileJSON.targetTypes[1])
    super(props);
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date();
    endDate.setHours(23, 59, 59, 59);

    this.state = {
      token:
        // "d1969b011RRR_RRR597461cd1836de351ac9e47FF372RRR_RRR5398FC74FB98E1249F828EAE374B974A56C",
      "8e830b269f8eaea1f85fa6d72____665673f7486EBAF0E67133917820AE9____3125891B75F1844DD", // amana-bagh2
      // "bc98c8124aff4fc99f29c16781eaedd028D93FF0D0B45FE517D6DA984A38A9D20E9DC3577", // برنامج الوزن الخارجي 
      // "92da88d4a9fe4539ca7c0c2f45de6bb0269F742F7E790FBBF5D8C9394BAAEE5E230530E22",// mizan-api 
      //  "e6d87ef6494edaa111f9a136da77b8c069EAA33B7C6B7266814BEFB7B6689973B24D3B455", // test app raaftsalih
      isAuthorized: false,
      username: "",
      res: [],
      logging: true,
      showAlert: false,
      alertVariant: "success",
      error: false,
      alertMSG: "",
      resultJSON: [],
      selectedRes: {},
      selectedReport: {},
      selectedObject: {},
      units: [],
      unitGroups: [],
      accounts: [],
      executing: false,
      exporting: false,
      fromDateVal: startDate,
      toDateVal: endDate,
      fromDateValUnix: this.convertSATToUnix(startDate),
      toDateValUnix: this.convertSATToUnix(endDate),
      targetTypes: TARGET_TYPES,
      destinations: DESTINATIONS,
      selectedTargetType: fileJSON.targetTypes[0], //fileJSON.targetTypes[1], // group is default
      selectedDestination: fileJSON.destinations[0],
      executedDestination: fileJSON.destinations[0],
      reports: REPORTS,
      selectedReportNew: fileJSON.repoerMaping[0],
      newData: { data: [] }, // for unit [{},{},....] , for group [[{},{},....],[],[]]
      tempData: [], // to hold prepared object arrays of final project
      overlayText: "جاري تسجيل الدخول...",
      loginMode: fileJSON.loginMode,
      isTried: false,
      dataTable: [],
      zones: [],
      filteredZones: [],
      zonesGroups: [],
      selectedZone: {},
      zoneGroup: {},
      isReady: false,
      timeLimitBefore: 150,
      timeLimitAfter: 120
    };
    this.fromDateOnChange = this.fromDateOnChange.bind(this);
    this.toDateOnChange = this.toDateOnChange.bind(this);
    this.propOpenFunc = this.propOpenFunc.bind(this);
    this.execute = this.execute.bind(this);
    this.onTargetTypeChange = this.onTargetTypeChange.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.onDestinationChange = this.onDestinationChange.bind(this);
    //  this.onUnitChange = this.onUnitChange.bind(this);
    this.onUnitGroupChange = this.onUnitGroupChange.bind(this);
    this.export = this.export.bind(this);
    this.setAuthHash = this.setAuthHash.bind(this);
    //  this.start = this.start.bind(this);
    this.auth = this.auth.bind(this);
    // this.onZoneChange = this.onZoneChange.bind(this);
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <Context.Provider
          value={{
            value: this.state,
            actions: {
              fromDateOnChange: this.fromDateOnChange,
              toDateOnChange: this.toDateOnChange,
              propOpen: this.propOpenFunc,
              execute: this.execute,
              onTargetTypeChange: this.onTargetTypeChange,
              onAccountChange: this.onAccountChange,
              onDestinationChange: this.onDestinationChange,
              //  onUnitChange: this.onUnitChange,
              onUnitGroupChange: this.onUnitGroupChange,
              export: this.export,
              setAuthHash: this.setAuthHash,
              //  start: this.start,
              auth: this.auth,
              onZoneChange: this.onZoneChange
            }
          }}
        >
          <Switch>
            <Route path="/start" exact component={MyNav} />
            <Route path="/" component={MyNav} />
          </Switch>
        </Context.Provider>
      </BrowserRouter>
    );
  }
  // async start(e) {
  //   //console.log('hash updated now... going to /')
  //   //await this.setState({authHash:hash})
  //   window.location.href = "/?authHash=09fe3bad5c5671d8c13b9b21bfc6e787";
  // }

  async componentDidMount() {
    // this.auth()
  }

  setAuthHash(aHashVal) {
    // console.log('h updated')
    this.setState({ authHash: aHashVal });
    // console.log('s c')
  }



  /**
   * will be called from MyNav after checking token or hash
   * @param {*} e 
   */
  // async auth1(e) {
  //   // console.log('passed hash')
  //   // console.log(e)

  //   this.auth(e);
  // }

  async auth(data) {
    const hashOrToken = data.hashOrToken
    const baseUrl = data.baseUrl
    //await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
    wialon.core.Session.getInstance().initSession(baseUrl);
    // Try to login when component mount
    if (this.state.loginMode === "token") {
      console.log('mode : T')
      this.loginWithToken(baseUrl);
    }
    if (this.state.loginMode === "authHash") {
      // console.log('mode : H')
      this.loginWithHash(hashOrToken, baseUrl);
    }
  }

  loginWithToken(baseUrl) {
    let that = this;
    console.log(baseUrl)
    wialon.core.Session.getInstance().initSession(baseUrl);

    wialon.core.Session.getInstance().loginToken(
      this.state.token,
      "", // operate as
      async code => {
        // login callback
        // if error code - print error message
        this.setState({ isTried: true });
        if (code) {
          that.setState({
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG:
              code === 4
                ? "دخول غير مخول"
                : wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        // console.log('authorized')
        await that.setState({
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }

  loginWithHash(hash, baseUrl) {
    wialon.core.Session.getInstance().initSession(baseUrl);

    let that = this;
    wialon.core.Session.getInstance().loginAuthHash(
      hash,
      "", // try to login
      async code => {
        this.setState({ isTried: true });

        // login callback
        // if error code - print error message
        if (code) {
          that.setState({
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG: wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        that.setState({
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }

  init() {
    // console.log('start init')
    let that = this;
    var sess = wialon.core.Session.getInstance();

    sess.loadLibrary("resourceReports");
    //  sess.loadLibrary("resourceZones");
    //  sess.loadLibrary("resourceZoneGroups");

    // flags to specify what kind of data should be returned
    let res_flags =
      wialon.item.Item.dataFlag.base |
      wialon.item.Resource.dataFlag.reports// |
    //   wialon.item.Resource.dataFlag.zoneGroups |
    //   wialon.item.Resource.dataFlag.zones; // 64 bit OR
    let unit_flags = 1;
    let unitGroup_flags = 1;

    sess.updateDataFlags(
      // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: unit_flags, mode: 0 },
        { type: "type", data: "avl_unit", flags: unitGroup_flags, mode: 0 }
      ], // Items specification

      function (code) {
        // updateDataFlags callback
        if (code) {
          // console.log("Error: " + wialon.core.Errors.getErrorText(code));
          return; // exit if error code
        }

        const resourcesArr = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
        // console.log("Res")
        // console.log(resourcesArr)
        that.setState({ res: resourcesArr });
        /**
         * select report at certain resource (set state with selected resource and report object)
         */
        that.findResource(resourcesArr);

        const unitGroups = sess.getItems("avl_unit_group");
        const units = sess.getItems("avl_unit");
        // console.log(units)
        // console.log(unitGroups)
        if (unitGroups.length === 0 && units.length === 0) {
          that.setState({
            showAlert: true,
            error: true,
            alertMSG: "لا توجد مجموعات او مركبات!",
            alertVariant: "error"
          });
          return;
        }

        that.setState({
          units: units,
          unitGroups: unitGroups,
          overlayText: ""
        });
      }
    );
  }

  /**
   * search for report name from the constData json file then get data from it (zones and groups of zones)
   * @param {*} res 
   * @returns 
   */
  findResource(res) {
    console.log("Checking resource Reports")
    // if (RES_NAME === "*") {

    for (let i = 0; i < res.length; i++) {
      // console.log(res[i])
      if (RES_NAME === res[i].$$user_name) {
        // console.log(res[i])
        // console.log(res[i])
        // console.log(res[i].$$user_reports)
        let that = this;
        // if there is reports in the resource
        if (res[i].$$user_reports) {
          // get report name and key
          for (let [key, value] of Object.entries(res[i].$$user_reports)) {

            if (key == 9) {
              // console.log("value")
              // console.log(value)
              // console.log(that.state.selectedReportNew.name)
            }
            // if (value.n.includes(that.state.selectedTargetType.reportName)) {
            // console.log("that.state.selectedReportNew")
            // console.log(that.state.selectedReportNew)
            // console.log("that.state.selectedReport")
            // console.log(that.state.selectedReport)
            if (value.n.includes(that.state.selectedReportNew.name)) {
              // console.log("FOUND = ")
              // console.log(value)
              // console.log("Found a report at resource index " + i + " named " + res[i].$$user_name)
              // default selected target type should be group
              that.setState({
                selectedRes: res[i],
                selectedReport: value
              });
              break;
            }
          }
        }
      }
    }
    // }
  }


  execute(e) {
    if (
      Object.entries(this.state.selectedRes).length === 0 &&
      this.state.selectedRes.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار المصدر",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedReport).length === 0 &&
      this.state.selectedReport.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار التقرير",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedObject).length === 0 &&
      this.state.selectedObject.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار الهدف",
        alertVariant: "error"
      });
      return;
    }

    if (
      Object.entries(this.state.selectedDestination).length === 0 &&
      this.state.selectedDestination.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار الوجهة",
        alertVariant: "error"
      });
      return;
    }
    // if (
    //   Object.entries(this.state.selectedZone).length === 0 &&
    //   this.state.selectedZone.constructor === Object
    // ) {
    //   this.setState({
    //     showAlert: true,
    //     error: true,
    //     alertMSG: "يرجى اختيار المنطقة",
    //     alertVariant: "error"
    //   });
    //   return;
    // }
    if (this.state.fromDateValUnix === "" || this.state.toDateValUnix === "") {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: " يرجى اختيار الفترة اولاً",
        alertVariant: "error"
      });
      return;
    }

    let interval = {
      from: this.state.fromDateValUnix,
      to: this.state.toDateValUnix,
      flags: wialon.item.MReport.intervalFlag.absolute
    };
    let that = this;
    let sess = wialon.core.Session.getInstance();
    let resou = sess.getItem(this.state.selectedRes._id);
    let template = resou.getReport(this.state.selectedReport.id);

    // first i need to set local datetime

    // this.prepareTempData(this.state.selectedObject);
    this.setState({
      isReady: false,
      executing: true,
      overlayText:
        "جاري تنفيذ التقرير... يرجى الانتظار... قد تستغرف عملية التنفيذ اكثر من 3 دقائق اذا كانت الفترة المحددة طويلة"
    });
    let localObj = {
      flags: 0,
      formatDate: "%25Y-%25m-%25E %25H:%25M:%25S"
    };
    sess.getRenderer().setLocale(134228528, "en", localObj, function (code) {
      if (code) {
        that.setState({
          showAlert: true,
          error: true,
          alertMSG: wialon.core.Errors.getErrorText(code),//"جدول البيانات فارغ, لا توجد بيانات",
          alertVariant: "error",
          executing: false,
          overlayText: "",
          isReady: false
        });
        return;
      }
      resou.execReport(
        template,
        that.state.selectedObject._id,
        0,
        interval,
        function (code, data) {
          if (code) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: wialon.core.Errors.getErrorText(code),
              alertVariant: "error"
            });
            return;
          }
          if (!data.getTables().length) {
            //console.log(data)
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: "جدول البيانات فارغ, لا توجد بيانات",
              alertVariant: "error",
              executing: false,
              overlayText: "",
              isReady: false
            });
            return;
          }
          // console.log(that.state)
          // that.setState({newData:[],tempData:[], overlayText: 'جاري بناء التقرير' })
          that.setState({
            executedDestination: that.state.selectedDestination
          })

          that.preBuild(data);
        }
      );
    });
  }

  preBuild(dataTable) {
    let tables = dataTable.getTables();
    let that = this;
    let visitsTableIndex = tables.findIndex(function (e) {
      return (
        e.label.includes("الشحنات")
        // && e.label.includes(that.state.selectedZone.n)
      );
    });
    let visitsTable = {};
    if (visitsTableIndex >= 0) {
      visitsTable = tables[visitsTableIndex];
    }

    let weightTableIndex = tables.findIndex(function (e) {
      return (
        e.label.includes("الوزن")
        // && e.label.includes(that.state.selectedZone.n)
      );
    });
    let weightsTable = {};
    if (weightTableIndex >= 0) {
      weightsTable = tables[weightTableIndex];
    }


    let visitsTable_narawan_Index = tables.findIndex(function (e) {
      console.log(e)
      return (
        e.label.includes("نهروان")
        // && e.label.includes(that.state.selectedZone.n)
      );
    });
    let visits_Table_narawan = {};
    if (visitsTable_narawan_Index >= 0) {
      visits_Table_narawan = tables[visitsTable_narawan_Index];
    }


    let obj = {
      visit: {
        table: visitsTable,
        index: visitsTableIndex,
        indexing: 1
      },
      weight: {
        table: weightsTable,
        index: weightTableIndex,
        indexing: 3
      },
      narawan: {
        table: visits_Table_narawan,
        index: visitsTable_narawan_Index,
        indexing: 2
      },
    };

    console.log(obj)

    this.build(dataTable, obj);
  }

  // Function to remove duplicates from an array
  removeDuplicates(array) {
    const uniqueItems = new Set();
    const result = [];

    for (const item of array) {
      const itemString = JSON.stringify(item.t1 + "_" + item.t2);
      if (!uniqueItems.has(itemString)) {
        uniqueItems.add(itemString);
        result.push(item);
      }
    }
    console.log(result)
    return result;
  }

  build(data, obj) {

    let visitsArr = [];
    let weightsArr = [];

    let that = this;



    const selected_destination = this.state.selectedDestination
    const selecte_visit_index = selected_destination.id == 1 ? obj.visit.index : obj.narawan.index
    const selecte_visit_table = selected_destination.id == 1 ? obj.visit : obj.narawan
    // console.log("selected_destination")
    // console.log(selected_destination)

    // console.log("selecte_visit_index")
    // console.log(selecte_visit_index)

    // console.log("obj.visit.index")
    // console.log(obj.visit.index)

    // console.log("obj.narawan.index")
    // console.log(obj.narawan.index)



    let reqObj = {
      type: "range",
      data: {
        from: 0,
        to: selecte_visit_table.table.rows,
        level: 5,
        flat: 0,
        rawValues: 0
      }
    };


    if (selecte_visit_index == -1) {
      that.setState({
        showAlert: true,
        error: true,
        alertMSG: "لا توجد بيانات للفترة المختارة",
        alertVariant: "error",
        executing: false,
        overlayText: "",
        isReady: true
      });
      return;
    }
    data.selectRows(selecte_visit_index, reqObj, function (code, col) {
      if (code && selecte_visit_index !== -1) {
        // means the error is real, then stop the detching
        that.setState({
          showAlert: true,
          error: true,
          alertMSG: wialon.core.Errors.getErrorText(code),
          alertVariant: "error"
        });
        return;
      }

      visitsArr = col;
      // console.log("visit array")
      // console.log(col)

      // fetch again
      reqObj = {
        type: "range",
        data: {
          from: 0,
          to: obj.weight.table.rows,
          level: 5,
          flat: 0,
          rawValues: 0
        }
      };
      // console.log("getting data from report")
      // console.log(obj.weight.index)
      // if (obj.weight.index != -1) {
        data.selectRows(obj.weight.index, reqObj, function (code2, col2) {
          // if (code2 && obj.parking.index !== -1) {
          if (code2 && obj.weight.index !== -1) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: wialon.core.Errors.getErrorText(code2),
              alertVariant: "error"
            });
            return;
          }
          weightsArr = obj.weight.index != -1 ? col2 : [];


          // if (that.state.selectedTargetType.id === 1) {
          // groups
          // console.log("visitsArr")
          // console.log(visitsArr)
          // console.log("weightsArr")
          // console.log(weightsArr)


          console.log("VISIT ARRAY")
          console.log(col)

          console.log("Weight ARRAY")
          console.log(col2)
          // Iterate through each object in the dataArray and remove duplicates from the 'r' array
          const cleanedArray = weightsArr.map(obj => ({
            ...obj,
            r: that.removeDuplicates(obj.r)
          }));
          // console.log("cleanedArray")
          // console.log(cleanedArray)

          // fill visits array and pair it with weights
          that.fillVisits(visitsArr); 
          // check weights and saved the weight to a ride
          that.fillWeights(cleanedArray);
          // console.log("before")
          // console.log(that.state.newData)
          that.pairVisitWeight();
          // console.log("after")
          // console.log(that.state.newData)
          that.setState({
            executing: false,
            overlayText: "",
            isReady: true
          });
          // }
          // if (that.state.selectedTargetType.id === 0) {
          //   // unit
          //   that.fillVisitsForUnit(obj.visit.table.total, visitsArr); // data is datatable

          //   that.setState({
          //     executing: false,
          //     overlayText: "",
          //     isReady: true
          //   });
          // }
          // that.calculateStatus();
        });
      // }
    });
  }

  fillVisits(visitsArr) {
    // let finalArr = this.state.tempData; // get temp array data
    let finalArr = []
    //  console.log(this.state.tempData)
    // console.log(visitsArr)
    for (let i = 0; i < visitsArr.length; i++) {
      let unitName = visitsArr[i].c[1]; // get unit Name
      let status = "";
      let r = visitsArr[i].r;
      //start with visits in marakez

      let visitsObj = {
        //   t1: visitsArr[i].c[2],
        //    t2: visitsArr[i].c[3],
        // totalKM: visitsArr[i].c[4],
        // totalDuration: visitsArr[i].c[5],
        // count: visitsArr[i].c[6],

        unitName: unitName,
        totalKm: visitsArr[i].c[4],
        totalDuration: visitsArr[i].c[5],
        rideCounts: visitsArr[i].c[6],
        weightCounts: 0,
        visits: [],
        weights: []
      };
      // console.log(' r of visits array');
      // console.log(r)
      for (let j = 0; j < r.length; j++) {

        // console.log(dtArr4)
        let detailsObj = {
          t1: r[j].c[2].t,
          t2: r[j].c[3].t,
          km: r[j].c[4],
          duration: r[j].c[5],
        };
        // console.log('details obj')
        // console.log(detailsObj)
        visitsObj.visits.push(detailsObj);
      }
      // console.log("visit obj")
      // console.log(visitsObj)
      // now update object of unit in final array

      // let index = finalArr.findIndex(e => e.unitName === unitName);
      // if (index >= 0) {
      //   finalArr[index].visits = visitsObj;
      // } else {
      //   // console.log('not found')
      // }
      finalArr[i] = visitsObj

    }
    this.setState({ newData: finalArr });
  }

  fillWeights(weightsArr) {
    let finalArr = this.state.newData; // get temp array data
    for (let i = 0; i < weightsArr.length; i++) {
      let unitName = weightsArr[i].c[1]; // get unit Name
      let status = "";
      let r = weightsArr[i].r;

      let weightObj = {
        unitName: unitName,
        // weightCount: weightsArr[i].c[4],
        weightCount: weightsArr[i].r.length,
        weights: []
      };

      for (let j = 0; j < r.length; j++) {
        let mizArr = r[j].c[3].split(",")
        let fullW = mizArr[4].split(":")[1].trim()
        let emptyW = mizArr[2].split(":")[1].trim()
        let weight = fullW - emptyW
        let date = mizArr[3].split(":")[1].trim() + ":" + mizArr[3].split(":")[2].trim() + ":" + mizArr[3].split(":")[3].trim()
        date = date.substr(0, date.lastIndexOf("."))
        let detailsObj = {
          t1: r[j].c[2],
          mizanStr: r[j].c[3],
          weight: weight,
          date: date
        };
        weightObj.weights.push(detailsObj);
      }
      let index = finalArr.findIndex(e => e.unitName === unitName);
      // check the weight with the trips
      if (index >= 0) {
        finalArr[index].weightCounts = weightObj.weightCount
        finalArr[index].weights = weightObj.weights;
      }
    }

    this.setState({ newData: finalArr });
  }

  pairVisitWeight() {
    let that = this
    let finalArr = this.state.newData; // get temp array data
    //console.log("pair visit")
    //console.log(finalArr)
    let tripsWithWeight = 0;
    let tripsWithoutWeight = 0;
    let totalWeight = 0;
    let weightsCount = 0;

    // trips array with all weights to particular unit, now need to map only one (the neareset)
    let newArr = finalArr.map(obj => {
      const seenDates = new Map();


      // const prom = new Promise(resolve => {
      //   obj.weights = obj.weights.filter(obj => {
      //     const date = obj.date;
      //     if (!seenDates.has(date)) {
      //       seenDates.set(date, true);
      //       return true;
      //     }
      //     return false;
      //   });
      // })
      let weights = obj.weights
      // console.log(weights)
      let visits = obj.visits
      obj.weights.forEach(function (weight, index) {
        weight.used = false
      });
      for (let i = 0; i < visits.length; i++) {
        obj.visits[i].weightDate = "---"
        obj.visits[i].weightNetWeight = "---"

        let currentVisit = visits[i]
        let amIdx = currentVisit.t1.indexOf("am")
        let pmIdx = currentVisit.t1.indexOf("pm")
        currentVisit.t1 = amIdx > -1 ? currentVisit.t1.substr(0, amIdx).trim() + " " + currentVisit.t1.substr(amIdx + 2, currentVisit.t1.length).trim() + " am" : currentVisit.t1
        currentVisit.t1 = pmIdx > -1 ? currentVisit.t1.substr(0, pmIdx).trim() + " " + currentVisit.t1.substr(pmIdx + 2, currentVisit.t1.length).trim() + " pm" : currentVisit.t1

        let amIdx2 = currentVisit.t2.indexOf("am")
        let pmIdx2 = currentVisit.t2.indexOf("pm")
        currentVisit.t2 = amIdx2 > -1 ? currentVisit.t2.substr(0, amIdx2).trim() + " " + currentVisit.t2.substr(amIdx2 + 2, currentVisit.t2.length).trim() + " am" : currentVisit.t2
        currentVisit.t2 = pmIdx2 > -1 ? currentVisit.t2.substr(0, pmIdx2).trim() + " " + currentVisit.t2.substr(pmIdx2 + 2, currentVisit.t2.length).trim() + " pm" : currentVisit.t2


        let visitTimestamp = moment(currentVisit.t1)
        var extractedWeight = weights.filter(function (obj1) {
          let weightDate = moment(obj1.date)//.format('YYYY-MM-DD hh:mm:ss a')
          let weightMinusVisit = visitTimestamp.diff(weightDate, 'minutes')
          //weightMinusVisit = Math.abs(weightMinusVisit)
          if (weightMinusVisit <= 0) {
            let condition = Math.abs(weightMinusVisit) < that.state.timeLimitBefore
            return condition //&& weightMinusVisit >= 0
          }
          else {
            let condition = weightMinusVisit < that.state.timeLimitAfter
            return condition //&& weightMinusVisit >= 0
          }

        })

        if (extractedWeight.length > 0) {
          weights[weights.indexOf(extractedWeight[0])].used = true;
          obj.visits[i].weightDate = moment(extractedWeight[0].date).format('YYYY-MM-DD hh:mm:ss a')
          obj.visits[i].weightNetWeight = extractedWeight[0].weight
          totalWeight += extractedWeight[0].weight
          tripsWithWeight += 1
        } else {
          tripsWithoutWeight += 1
        }
        //console.log(extractedWeight)
      }
      // console.log(obj)
      weightsCount += weights.length
      return obj
    });
    let weightNoTrips = weightsCount - tripsWithWeight
    newArr = {
      tripsNoWeight: tripsWithoutWeight,
      tripsWithWeight: tripsWithWeight,
      totalWeight: totalWeight,
      weightNoTrips: weightNoTrips,
      data: newArr
    }
    // console.log(newArr)
    this.setState({ newData: newArr });
  }

  fromDateOnChange(e) {
    let from = this.convertSATToUnix(e);
    if (!this.state.fromDateVal === "") {
      if (e) {
        if (this.compareTime(from, this.state.toDateValUnix)) {
          this.setState({ fromDateValUnix: from, fromDateVal: e });
        } else {
          this.setState({
            showAlert: true,
            error: true,
            alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
          });
        }
      }
    } else {
      this.setState({ fromDateValUnix: from, fromDateVal: e });
    }
  }
  toDateOnChange(e) {
    let to = this.convertSATToUnix(e);
    if (e) {
      if (this.compareTime(this.state.fromDateValUnix, to)) {
        this.setState({ toDateValUnix: to, toDateVal: e });
      } else {
        this.setState({
          showAlert: true,
          error: true,
          alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
        });
      }
    }
  }
  convertSATToUnix(sat) {
    let longVal = Math.floor(new Date(sat).getTime() / 1000);
    return longVal;
  }
  compareTime(from, to) {
    return from <= to;
  }
  propOpenFunc(e) {
    this.setState({
      showAlert: !this.state.showAlert,
      alertMSG: "",
      error: false
    });
  }

  async onTargetTypeChange(e) {
    console.log("ON TARGET TYPE CHANGED")
    console.log(e)
    if (e) {
      let selObj =
        e.id === 0
          ? this.state.units.length > 0
            ? this.state.units[0]
            : {}
          : this.state.unitGroups.length > 0
            ? this.state.unitGroups[0]
            : {};

      await this.setState({
        selectedTargetType: e,
        newData: { data: [] },
        selectedObject: selObj
      });
      this.findResource(this.state.res);
    }
  }

  async onDestinationChange(e) {
    console.log("ON DESTINATION CHANGED")
    console.log(e)
    if (e) {

      await this.setState({
        selectedDestination: e,
        newData: { data: [] }
      });
      this.findResource(this.state.res);
    }
  }

  async onAccountChange(e) {
    console.log("ON ACCOUNT CHANGED")
    console.log(e)
    if (e) {

      await this.setState({
        selectedReportNew: e,
        newData: { data: [] }
      });
      this.findResource(this.state.res);
    }
  }

  // onUnitChange(e) {
  //   if (e) {
  //     this.setState({
  //       selectedObject: e,
  //       selectedTargetType: fileJSON.targetTypes[0]
  //     });
  //     // this.prepareTempData(e);
  //   }
  // }

  onUnitGroupChange(e) {
    // console.log(e)
    if (e) {
      this.setState({
        selectedObject: e,
        selectedTargetType: fileJSON.targetTypes[0]
      });
      // this.prepareTempData(e);
    }
  }

  export(e) {
    console.log(this.state.executedDestination)
    let reportName = "تقرير  - (" + this.state.selectedObject.$$user_name + ")" + " _ " + this.state.executedDestination.title
    let fileName = reportName + ".xlsx";
    let str =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let dt = this.state.newData;
    //Creating New Workbook
    let dataStartAtIdx = 8;

    let workbook = new ExcelJS.Workbook();
    //Creating Sheet for that particular WorkBook
    let sheetName = "كل النقلات";
    let sheetName2 = "الملخص";
    let sheetName3 = "اوزان بلا رحلة";
    let sheetName4 = "نقلات مع وزن";
    let sheetName5 = "نقلات بدون وزن";

    let sheet4 = workbook.addWorksheet(sheetName4, {
      pageSetup: { paperSize: 9, orientation: "landscape" }, // 9 is A4 page
      properties: { showGridLines: true },
      views: [
        { state: 'frozen', ySplit: dataStartAtIdx - 1 }
      ]
    });

    let sheet5 = workbook.addWorksheet(sheetName5, {
      pageSetup: { paperSize: 9, orientation: "landscape" }, // 9 is A4 page
      properties: { showGridLines: true },
      views: [
        { state: 'frozen', ySplit: dataStartAtIdx - 1 }
      ]
    });

    let sheet = workbook.addWorksheet(sheetName, {
      pageSetup: { paperSize: 9, orientation: "landscape" }, // 9 is A4 page
      properties: { showGridLines: true },
      views: [
        { state: 'frozen', ySplit: dataStartAtIdx - 1 }
      ]
    });

    let sheet2 = workbook.addWorksheet(sheetName2, {
      pageSetup: { paperSize: 9, orientation: "landscape" }, // 9 is A4 page
      properties: { showGridLines: true },
      views: [
        { state: 'frozen', ySplit: dataStartAtIdx - 1 }
      ]
    });

    let sheet3 = workbook.addWorksheet(sheetName3, {
      pageSetup: { paperSize: 9, orientation: "landscape" }, // 9 is A4 page
      properties: { showGridLines: true },
      views: [
        { state: 'frozen', ySplit: dataStartAtIdx - 1 }
      ]
    });




    this.createSheetHeader(sheet4, dataStartAtIdx, dt, reportName)
    this.createSheetHeader(sheet5, dataStartAtIdx, dt, reportName)

    this.createSheetHeader(sheet, dataStartAtIdx, dt, reportName)
    this.createSheetHeader(sheet2, dataStartAtIdx, dt, reportName)
    this.createSheetHeader(sheet3, dataStartAtIdx, dt, reportName)

    this.createSheet4(sheet4, dataStartAtIdx, dt)
    this.createSheet5(sheet5, dataStartAtIdx, dt)

    this.createSheet1(sheet, dataStartAtIdx, dt)
    this.createSheet2(sheet2, dataStartAtIdx, dt)
    this.createSheet3(sheet3, dataStartAtIdx, dt)




    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: str });
      saveAs(blob, fileName);
    });
  }

  borderAll(sheet) {
    // border all
    sheet.eachRow(function (row, rowNumber) {
      // if (rowNumber > minus1) row.style = that.styleCell();
      row.eachCell(function (cell, rowNumberCell) {
        if (rowNumberCell < 14) {
          // console.log(rowNumberCell)
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          cell.alignment = { vertical: "top", horizontal: "center" };
        }
      });
    });
  }


  createSheet1(sheet, dataStartAtIdx, dt) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    sheet.getRow(minus2).values = [
      "تفاصيل الرحلة",
      // "الحضور",
      "تفاصيل الرحلة", // c
      "تفاصيل الرحلة", // d
      "تفاصيل الرحلة", // e
      "تفاصيل الرحلة", // f

      "المحطة التحويلية", // g
      "المحطة التحويلية", // h

    ];

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",
      "الانطلاق", // c
      "الوصول", // d
      "المدة", // e
      "المسافة", // f

      "وزن الشحنة ", // g
      "وقت الشحنة", // h
    ];


    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 36 },
      //    { key: "status", width: 6.44 },
      { key: "trip1", width: 21 }, // c
      { key: "trip2", width: 21 }, // d
      { key: "trip3", width: 8 }, // e
      { key: "trip4", width: 7 }, // f
      { key: "mizan1", width: 10 }, // g
      { key: "mizan2", width: 21 }, // h
    ];

    sheet.mergeCells("A" + minus2 + ":F" + minus2); // mergce center details
    sheet.mergeCells("G" + minus2 + ":H" + minus2); // merge eh

    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFF00'
        }
      };
    });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    for (let i in dt.data) {

      sheet.addRow({ name: dt.data[i].unitName, status: dt.data[i].status });

      for (let j = 0 + index, counter = 0; j < dt.data[i].visits.length + index; j++) {
        sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
        sheet.getCell("B" + sheetCounter).value = dt.data[i].unitName
        sheet.getCell("C" + sheetCounter).value = dt.data[i].visits[counter].t1
        sheet.getCell("D" + sheetCounter).value = dt.data[i].visits[counter].t2
        sheet.getCell("E" + sheetCounter).value = dt.data[i].visits[counter].duration
        sheet.getCell("F" + sheetCounter).value = dt.data[i].visits[counter].km
        if (dt.data[i].visits[counter].km === "0.00 km") {
          sheet.getCell("F" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        }


        sheet.getCell("G" + sheetCounter).value = dt.data[i].visits[counter].weightNetWeight
        if (dt.data[i].visits[counter].weightNetWeight === "---")
          sheet.getCell("G" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        sheet.getCell("H" + sheetCounter).value = dt.data[i].visits[counter].weightDate
        if (dt.data[i].visits[counter].weightDate === "---")
          sheet.getCell("H" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        counter++
        sheetCounter++
      }

      // sheet
      //   .getRow(index)
      //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //     cell.style = that.styleCell();
      //   });
      // index = index + max;
    }
    this.borderAll(sheet)
  }

  createSheet2(sheet, dataStartAtIdx, dt, reportName) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    sheet.getRow(minus2).values = [
      "الملخص",
      // "الحضور",
      "الملخص", // c
      "الملخص", // d
      "الملخص", // e
      "الملخص", // f

      "الملخص", // g
      "الملخص", // h

    ];

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",
      "شحنات بوزن", // c
      "شحنات بدون وزن", // d
      "المجموع", // e

      "الوزن كغم (رحلة)", // f
      "الوزن كغم (رحلة)", // g

      "عدد الاوزان بدون رحلة", // h
    ];


    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 36 },
      //    { key: "status", width: 6.44 },
      { key: "tripWeight", width: 21 }, // c
      { key: "tripnoWehgit", width: 21 }, // d
      { key: "weight", width: 8 }, // e
      //   { key: "trip3", width: 8 }, // e
      { key: "trip4", width: 7 }, // f
      { key: "mizan1", width: 10 }, // g
      { key: "mizan2", width: 21 }, // h
    ];

    sheet.mergeCells("A" + minus2 + ":H" + minus2); // تفاصيل
    // sheet.mergeCells("E" + minus1 + ":F" + minus1); // مجموع
    // sheet.mergeCells("G" + minus1 + ":H" + minus1); // طن
    sheet.mergeCells("F" + minus1 + ":G" + minus1); // وزن بدون رحلة

    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFF00'
        }
      };
    });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    // console.log(dt.data)
    for (let i in dt.data) {

      sheet.addRow({ name: dt.data[i].unitName, status: dt.data[i].status });
      sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
      sheet.getCell("B" + sheetCounter).value = dt.data[i].unitName
      //sheet.mergeCells("E" + sheetCounter + ":F" + sheetCounter); // المجموع
      sheet.getCell("E" + sheetCounter).value = dt.data[i].visits.length // مجموع
      let allTrips = dt.data[i].visits.length
      let allWeights = dt.data[i].weights.length
      let totalTon = 0
      let tripsWithWeigh = 0
      let tripsWithNoWeith = 0
      let weightNoTripsBound = 0
      for (let j = 0 + index, counter = 0; j < allTrips + index; j++) {
        if (dt.data[i].visits[counter].weightNetWeight !== "---") {
          totalTon += dt.data[i].visits[counter].weightNetWeight
          tripsWithWeigh++
        } else {
          tripsWithNoWeith++
        }

        counter++
      }

      if (dt.data[i].weights.length > 0) {
        for (let k = 0; k < allWeights; k++) {
          // console.log(dt.data[i].weights[k])
          if (dt.data[i].weights[k].used == false) {
            weightNoTripsBound++
          }
        }
      }

      // tripsWithNoWeith = allTrips - tripsWithWeigh
      sheet.getCell("C" + sheetCounter).value = tripsWithWeigh
      sheet.getCell("D" + sheetCounter).value = tripsWithNoWeith
      if (tripsWithNoWeith > 0) {
        sheet.getCell("D" + sheetCounter).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF0000'
          }
        }
      }
      sheet.mergeCells("F" + sheetCounter + ":G" + sheetCounter); // وزن بدون رحلة

      // sheet.mergeCells("G" + sheetCounter + ":H" + sheetCounter); // طن
      sheet.getCell("F" + sheetCounter).value = totalTon
      sheet.getCell("H" + sheetCounter).value = weightNoTripsBound
      if (weightNoTripsBound > 0) {
        sheet.getCell("H" + sheetCounter).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FF0000'
          }
        }
      }
      sheetCounter++
      // console.log(dt.data[i])
      //   // sheet
      //   //   .getRow(index)
      //   //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //   //     cell.style = that.styleCell();
      //   //   });
      //   // index = index + max;
    }
    this.borderAll(sheet)
  }





  createSheet3(sheet, dataStartAtIdx, dt) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    sheet.getRow(minus2).values = [
      "الاوزان الغير مرتبطة برحلة",

      "الاوزان الغير مرتبطة برحلة",

      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",
      "الاوزان الغير مرتبطة برحلة",

    ];

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",
      "الوزن القائم", // c
      "تاريخ الوزن القائم", // d


      "الوزن الفارغ",// e
      "تاريخ الوزن الفارغ", // f
      "تاريخ الوزن الفارغ", // g
      "الوزن الصافي",// h

    ];


    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 36 },
      //    { key: "status", width: 6.44 },
      { key: "tripWeight", width: 21 }, // c
      { key: "tripnoWehgit", width: 21 }, // d
      { key: "weight", width: 8 }, // e
      //   { key: "trip3", width: 8 }, // e
      { key: "trip4", width: 7 }, // f
      { key: "mizan1", width: 10 }, // g
      { key: "mizan2", width: 21 }, // h
    ];

    sheet.mergeCells("A" + minus2 + ":H" + minus2); // تفاصيل
    // sheet.mergeCells("E" + minus1 + ":F" + minus1); // الوزن الفارغ

    sheet.mergeCells("F" + minus1 + ":G" + minus1); // تاريخ الوزن الفارغ

    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFF00'
        }
      };
    });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    console.log(dt)
    for (let i in dt.data) {

      let allWeights = dt.data[i].weights.length

      if (dt.data[i].weights.length > 0) {
        for (let k = 0; k < allWeights; k++) {
          if (!dt.data[i].weights[k].used) {
            sheet.addRow({ name: dt.data[i].unitName, status: dt.data[i].status });
            sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
            sheet.getCell("B" + sheetCounter).value = dt.data[i].unitName
            let mizArr = dt.data[i].weights[k].mizanStr.split(",")
            let fullW = mizArr[4].split(":")[1].trim()
            let emptyW = mizArr[2].split(":")[1].trim()
            let weight = fullW - emptyW
            let date = mizArr[3].split(":")[1].trim() + ":" + mizArr[3].split(":")[2].trim() + ":" + mizArr[3].split(":")[3].trim()
            date = date.substr(0, date.lastIndexOf("."))
            let datewmpty = "---"
            if (mizArr[1].includes(".")) {
              datewmpty = mizArr[1].split(":")[1].trim() + ":" + mizArr[1].split(":")[2].trim() + ":" + mizArr[1].split(":")[3].trim()
              datewmpty = datewmpty.substr(0, date.lastIndexOf("."))
            }


            sheet.getCell("C" + sheetCounter).value = parseInt(fullW)
            sheet.getCell("D" + sheetCounter).value = date
            sheet.getCell("E" + sheetCounter).value = emptyW
            sheet.getCell("F" + sheetCounter).value = datewmpty
            sheet.getCell("H" + sheetCounter).value = weight

            sheet.mergeCells("F" + sheetCounter + ":G" + sheetCounter); // تاريخ الوزن القائم	
            sheetCounter++
          }
        }
      }


      // console.log(dt.data[i])
      //   // sheet
      //   //   .getRow(index)
      //   //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //   //     cell.style = that.styleCell();
      //   //   });
      //   // index = index + max;
    }
    this.borderAll(sheet)
  }


  createSheet4(sheet, dataStartAtIdx, dt) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    sheet.getRow(minus2).values = [
      "تفاصيل الرحلة",
      // "الحضور",
      "تفاصيل الرحلة", // c
      "تفاصيل الرحلة", // d
      "تفاصيل الرحلة", // e
      "تفاصيل الرحلة", // f

      "المحطة التحويلية", // g
      "المحطة التحويلية", // h

    ];

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",
      "الانطلاق", // c
      "الوصول", // d
      "المدة", // e
      "المسافة", // f

      "وزن الشحنة ", // g
      "وقت الشحنة", // h
    ];


    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 36 },
      //    { key: "status", width: 6.44 },
      { key: "trip1", width: 21 }, // c
      { key: "trip2", width: 21 }, // d
      { key: "trip3", width: 8 }, // e
      { key: "trip4", width: 7 }, // f
      { key: "mizan1", width: 10 }, // g
      { key: "mizan2", width: 21 }, // h
    ];

    sheet.mergeCells("A" + minus2 + ":F" + minus2); // mergce center details
    sheet.mergeCells("G" + minus2 + ":H" + minus2); // merge eh

    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFF00'
        }
      };
    });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index

    console.log("dt.data")
    console.log(dt.data)
    for (let i in dt.data) {
      const allWeightsAreEmpty = dt.data[i].visits.every(
        visit => visit.weightNetWeight === "---"
      );
      if (!allWeightsAreEmpty)
        sheet.addRow({ name: dt.data[i].unitName, status: dt.data[i].status });

      for (let j = 0 + index, counter = 0; j < dt.data[i].visits.length + index; j++) {
        if (dt.data[i].visits[counter].weightNetWeight === "---") { counter++; continue; }

        sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
        sheet.getCell("B" + sheetCounter).value = dt.data[i].unitName
        sheet.getCell("C" + sheetCounter).value = dt.data[i].visits[counter].t1
        sheet.getCell("D" + sheetCounter).value = dt.data[i].visits[counter].t2
        sheet.getCell("E" + sheetCounter).value = dt.data[i].visits[counter].duration
        sheet.getCell("F" + sheetCounter).value = dt.data[i].visits[counter].km
        if (dt.data[i].visits[counter].km === "0.00 km") {
          sheet.getCell("F" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        }


        sheet.getCell("G" + sheetCounter).value = dt.data[i].visits[counter].weightNetWeight
        if (dt.data[i].visits[counter].weightNetWeight === "---")
          sheet.getCell("G" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        sheet.getCell("H" + sheetCounter).value = dt.data[i].visits[counter].weightDate
        if (dt.data[i].visits[counter].weightDate === "---")
          sheet.getCell("H" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        counter++
        sheetCounter++
      }

      // sheet
      //   .getRow(index)
      //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //     cell.style = that.styleCell();
      //   });
      // index = index + max;
    }
    this.borderAll(sheet)
  }

  createSheet5(sheet, dataStartAtIdx, dt) {
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format
    sheet.getRow(minus2).values = [
      "تفاصيل الرحلة",
      // "الحضور",
      "تفاصيل الرحلة", // c
      "تفاصيل الرحلة", // d
      "تفاصيل الرحلة", // e
      "تفاصيل الرحلة", // f

      "المحطة التحويلية", // g
      "المحطة التحويلية", // h

    ];

    sheet.getRow(minus1).values = [
      "#",
      "اسم المركبة",
      //  "الحضور",
      "الانطلاق", // c
      "الوصول", // d
      "المدة", // e
      "المسافة", // f

      "وزن الشحنة ", // g
      "وقت الشحنة", // h
    ];


    sheet.columns = [
      { key: "#", width: 5 },
      { key: "name", width: 36 },
      //    { key: "status", width: 6.44 },
      { key: "trip1", width: 21 }, // c
      { key: "trip2", width: 21 }, // d
      { key: "trip3", width: 8 }, // e
      { key: "trip4", width: 7 }, // f
      { key: "mizan1", width: 10 }, // g
      { key: "mizan2", width: 21 }, // h
    ];

    sheet.mergeCells("A" + minus2 + ":F" + minus2); // mergce center details
    sheet.mergeCells("G" + minus2 + ":H" + minus2); // merge eh

    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFF00'
        }
      };
    });

    sheet.getRow(minus1).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // write the data
    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    // const tempData1 =  dt.data.filter(item => {
    //   return item.visits.some(visit => visit.weightNetWeight === '---');
    // });
    const filteredData = dt//{data:[]}
    // const dataInside = tempData1.map(item => (
    //    item.visits.filter(visit => visit.weightNetWeight == '---')
    // ));
    // filteredData.data = dataInside
    // console.log(filteredData)
    for (let i in filteredData.data) {
      // sheet.addRow({ name: filteredData.data[i].unitName, status: filteredData.data[i].status });

      for (let j = 0 + index, counter = 0; j < filteredData.data[i].visits.length + index; j++) {
        // console.log("counter = "+counter + " , index = "+index  + " , j = "+ j + " , i = "+ i) 
        if (filteredData.data[i].visits[counter].weightNetWeight != "---") { counter++; continue }
        // console.log("counter = " + counter + " , index = " + index + " , j = " + j + " , i = " + i)
        sheet.getCell("A" + sheetCounter).value = sheetCounter - index + 1
        sheet.getCell("B" + sheetCounter).value = filteredData.data[i].unitName
        sheet.getCell("C" + sheetCounter).value = filteredData.data[i].visits[counter].t1
        sheet.getCell("D" + sheetCounter).value = filteredData.data[i].visits[counter].t2
        sheet.getCell("E" + sheetCounter).value = filteredData.data[i].visits[counter].duration
        sheet.getCell("F" + sheetCounter).value = filteredData.data[i].visits[counter].km
        if (filteredData.data[i].visits[counter].km === "0.00 km") {
          sheet.getCell("F" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        }


        sheet.getCell("G" + sheetCounter).value = filteredData.data[i].visits[counter].weightNetWeight
        if (filteredData.data[i].visits[counter].weightNetWeight === "---")
          sheet.getCell("G" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        sheet.getCell("H" + sheetCounter).value = filteredData.data[i].visits[counter].weightDate
        if (filteredData.data[i].visits[counter].weightDate === "---")
          sheet.getCell("H" + sheetCounter).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000'
            }
          };
        counter++
        sheetCounter++
      }

      // sheet
      //   .getRow(index)
      //   .eachCell({ includeEmpty: true }, function (cell, colNumber) {
      //     cell.style = that.styleCell();
      //   });
      // index = index + max;
    }
    this.borderAll(sheet)
  }

  createSheetHeader(sheet, dataStartAtIdx, dt, reportName) {
    let minus5 = dataStartAtIdx - 5
    let minus4 = dataStartAtIdx - 4
    let minus3 = dataStartAtIdx - 3
    // let minus2 = dataStartAtIdx - 2
    // let minus1 = dataStartAtIdx - 1
    sheet.getRow(minus5).values = [
      "نظام وينك لتعقب المركبات",
      "نظام وينك لتعقب المركبات",
      "نظام وينك لتعقب المركبات",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة"
    ]


    sheet.getRow(minus4).values = [
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),

      "عدد المركبات = " + dt.data.length,
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),

      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD'),
      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD')
    ]


    let totalTrips = dt.tripsNoWeight + dt.tripsWithWeight
    let weightTon = dt.totalWeight / 1000
    let weightNoTrips = dt.weightNoTrips

    sheet.getRow(minus3).values = [
      "الاوزان الغير مرتبطة برحلة = " + weightNoTrips,
      "الاوزان الغير مرتبطة برحلة = " + weightNoTrips,
      // "الحضور",
      "النقلات الكلية = " + totalTrips,
      "النقلات بدون وزن = " + dt.tripsNoWeight,
      "النقلات مع وزن = " + dt.tripsWithWeight,
      "",

      "",
      "وزن النقلات = " + weightTon + " طن",

    ];



    sheet.mergeCells("A1:H2"); // merge name vertically // title of report

    // sheet.mergeCells("A" + minus2 + ":F" + minus2); // mergce center details
    // sheet.mergeCells("G" + minus2 + ":H" + minus2); // merge eh
    sheet.mergeCells("E" + minus3 + ":G" + minus3); // شحنات مع وزن

    sheet.mergeCells("A" + minus3 + ":B" + minus3); // الاوزان الغير مرتبطة برحلة

    sheet.mergeCells("A" + minus4 + ":B" + minus4); // وقت التنفيذ
    sheet.mergeCells("D" + minus4 + ":F" + minus4); // نهاية الفترة
    sheet.mergeCells("G" + minus4 + ":H" + minus4); //  بداية الفترة

    sheet.mergeCells("A" + minus5 + ":C" + minus5); //  نظام وينك لتعقب المركبات
    sheet.mergeCells("D" + minus5 + ":H" + minus5); //  شركة ركن الحلول المتكاملة


    // يجب اضافة وقت تنفيذ التقرير + المركبات الموزنة بدون ارتباط بشحنات + تفاصيل الوزن


    sheet.getCell("A1").value = reportName;
    sheet.getCell("A1").font = { size: 18, bold: true };
    sheet.getCell("A1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    };
    sheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center"
    };

    sheet.getRow(minus3).font = { size: 11, bold: true };
    sheet.getRow(minus4).font = { size: 11, bold: true };
    sheet.getRow(minus5).font = { size: 13, bold: true };


  }

  styleCell() {
    let style = {
      font: { size: 10, bold: false, name: "Arial" },
      alignment: { vertical: "middle", horizontal: "center" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
    };
    return style;
  }


}

export default App;
